import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../components/layout'
import { login } from '../../redux/actions';
import { getStore } from '../../redux/store';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({email: '', password: ''});
  const {email, password} = form;
  const setField = field => event => setForm({...form, [field]: event.target.value});

  const onSubmit = () => dispatch(login({email, password}));

  return (
    <Layout>
      <input type="email" value={email} onChange={setField("email")} placeholder="example@example.com"/>
      <input type="password" value={password} onChange={setField("password")} placeholder="********"/>
      <button onClick={onSubmit}>Se connecter</button>
    </Layout>
  );
}

export default LoginPage;